import { render, staticRenderFns } from "./WAConfig.vue?vue&type=template&id=0e4781a1&scoped=true"
import script from "./WAConfig.vue?vue&type=script&lang=js"
export * from "./WAConfig.vue?vue&type=script&lang=js"
import style0 from "./WAConfig.vue?vue&type=style&index=0&id=0e4781a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e4781a1",
  null
  
)

export default component.exports